import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220726 - Peach Peach!" />
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220726.jpg"
          width={400}
          quality={95}
        />
      </div>
      <h3 className="font-title-sub text-center">Peach Peach!</h3>
      <p>Finally I got the peach frappucino on starbucks. (Last time when I visited it was sold out.) The flesh of fruit was a bit tricky to suck up.</p>
      <p>After finished drinks, it seemed a bit reluctant to go home straight, so we decided to go for a drive to Matsushima. There were wild gold-banded lilies, and the view from the observation deck was very beautiful.</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
